import './Gallery.scss'

const Gallery = props => {
    console.log(props.pictures)
 return (
    <div className="gallerycontainer">
        {props.pictures.map((logo, i) => {
            return (
                <div className="galleryitem">
                    <img key={i} src={logo} />
                </div>
            )
        })}
    </div>
 )
}

export default Gallery;