import styled, { css } from 'styled-components'

const SectionHolder = styled.div`
    ${props => {
        if (props.background) {
            return css`background: url(${props => props.background}) center center;`
        } else if (props.backgroundColor) {
            return css`background: ${props => props.backgroundColor};`
        } else {
            return css`background: white;`
        }
    }}
    ${props => props.fullscreen && 'height: 100vh'};
    ${props => props.minheight && `min-height: ${props.minheight}`};
    ${props => props.textcolor && `color:${props.textcolor}`};
    background-size: cover;
    ${props => props.fullscreen ? 'padding: 0' : 'padding: 50px 10px'};
`

const ContentWrapper = styled.div`
    position: relative;
    width: 1100px;
    max-width: 100%;
    margin: auto;
    height: 100%;
`

const Section = props => {
    console.log(typeof props.background)
    return (
        <>
            <SectionHolder background={props.background} backgroundColor={props.backgroundColor} textcolor={props.textcolor} fullscreen={props.fullscreen} minheight={props.minheight}>
                <ContentWrapper>
                    {props.children}
                </ContentWrapper>
            </SectionHolder>
        </>
    )
}

export default Section