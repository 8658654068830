import Slide from '../UIAreas/Slide'
import Slide1 from '../assets/img/gallery1.png'
import Slide2 from '../assets/img/gallery2.png'
import Slide3 from '../assets/img/gallery3.png'
import Logo1 from '../assets/img/logo1-white.svg'
import Logo2 from '../assets/img/logo2-white.svg'
import Logo3 from '../assets/img/logo3-white.svg'

import ButtonNext from '../shared/ButtonNext'

import './FeaturedWork.scss'

const HeroSection = props => {
    return (
        <>
            <h2>Featured Work</h2>
            <div className="slideshowcontainer">
                <div className="slideshow">
                    <Slide
                        id={1}
                        text="First Swiss nano satellite in space"
                        img={Slide1}
                        logo={Logo1}
                    />
                    <Slide
                        id={2}
                        text="The largest suppliers of medical diagnostics in Europe"
                        img={Slide2}
                        logo={Logo2}
                    />
                    <Slide
                        id={3}
                        text="Teaching today for tomorrow's work"
                        img={Slide3}
                        logo={Logo3}
                    />
                </div>
            </div>
            <ButtonNext text="Discover all works" centered black/>
        </>
    )
}

export default HeroSection