import './Clients.scss'
import Gallery from '../UIAreas/Gallery'

import ClientLogo1 from '../assets/img/client1.svg'
import ClientLogo2 from '../assets/img/client2.svg'
import ClientLogo3 from '../assets/img/client3.svg'
import ClientLogo4 from '../assets/img/client4.svg'
import ClientLogo5 from '../assets/img/client5.svg'
import ClientLogo6 from '../assets/img/client6.svg'
import ClientLogo7 from '../assets/img/client7.svg'
import ClientLogo8 from '../assets/img/client8.svg'

const galleryPictures = [
    ClientLogo1,
    ClientLogo2,
    ClientLogo3,
    ClientLogo4,
    ClientLogo5,
    ClientLogo6,
    ClientLogo7,
    ClientLogo8
]

const Clients = props => {
    return (
        <>
            <h2>Some of our clients</h2>
            <Gallery pictures={galleryPictures} />
        </>
    )
}

export default Clients