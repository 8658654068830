import './Hero.scss'

const HeroSection = props => {
    return (
        <>  
            <nav>
                <span className="maintitle">Wow</span>
            </nav>
            <div className="hero-container">
                <div className="hero-text">
                    <h1>ExtraWowrdinary</h1>
                    <span className="subtitle">An almost ordinary journey - 04.23.2021</span>
                </div>
                <div className="hero-nexticon">&#8595;</div>
            </div>
        </>
    )
}

export default HeroSection