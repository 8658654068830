import styled from 'styled-components'

const ButtonArrowContainer = styled.div`
    display: flex;

    >div {
        display: flex;
        padding: 10px 50px;
        border: 4px solid black;
        border-radius: 9999px;
        margin: auto;
        font-size: 2em;
        font-weight: bold;

        >div {
            margin-right: 15px;
        }
    }
`

const ButtonArrow = props => {
 return (
    <ButtonArrowContainer>
        <div>
            <div>&#8594;</div>
            <span>{props.text}</span>
        </div>
    </ButtonArrowContainer>
 )
}

export default ButtonArrow;