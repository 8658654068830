import styled, { css } from 'styled-components'

import Wopen from '../assets/img/Wopen-white.svg'
import Wclose from '../assets/img/Wclose-white.svg'

import './Slide.scss'

const SlideContent = styled.div`
    position: relative;
    background: url(${props => props.background}) center center;
    background-size: cover;
    height: 100%;
    width: 100%;
`

const Slide = props => {
 return (
    <div className={`slidecontainer slide${props.id}`}>
        <SlideContent background={props.img}>
            <div className="slidercontent">
                <div className="sliderlogo">
                    <img src={props.logo} />
                </div>
                <div className="slidertext">
                    <div>
                        <img src={Wopen} />
                        <span>{props.text}</span>
                        <img src={Wclose} />
                    </div>
                </div>
            </div>
        </SlideContent>
    </div>
 )
}

export default Slide;