import './Motive.scss'
import ButtonNext from '../shared/ButtonNext'

const Motive = props => {
    return (
        <div className="motive">
            <p>{props.text}</p>
            <ButtonNext text={props.button} black={props.textcolor=== 'black'} circled/>
        </div>
    )
}

export default Motive