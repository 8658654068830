import './App.css';

import HeroBackground from './assets/img/hero_bg.png'
import Team from './assets/img/team.png'

import Section from './UIAreas/Section';
import HeroSection from './sections/Hero'
import FeaturedWork from './sections/FeaturedWork'
import Motive from './sections/Motive';
import Clients from './sections/Clients';
import Contact from './sections/Contact';

function App() {
  return (
    <>
      <Section
        background={HeroBackground}
        fullscreen
      >
        <HeroSection />
      </Section>
      <Section
        backgroundColor='white'
      >
        <FeaturedWork />
      </Section>
      <Section
        textcolor="white"
        backgroundColor="#202124"
      >
        <Motive textcolor="white" text="We are your Mind, Hand &amp; Heart Power" button="Let's meet" />
      </Section>
      <Section
        textcolor="#9BA0A5"
        backgroundColor="#F9F9F9"
      >
        <Clients />
      </Section>
      <Section
        textcolor="black"
        backgroundColor="white"
      >
        <Motive textcolor="black" text="Whiz kids both geeks and salesmen, your mind and hand power, who kick your butt and rock your world taking care of everything and anything." button="Who are the wowrriors" />
      </Section>
      <Section
        background={Team}
        minheight="600px"
      >
      </Section>
      <Section
        backgroundColor="#F9F9F9"
      >
        <Contact />
      </Section>
    </>
  );
}

export default App;
