import styled, { css } from 'styled-components'

const ButtonNextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.centered ? 'center' : 'start'};
    width: 100%;
    font-size: 2em;
    margin: 50px 0;
    font-weight: bold;
    ${props => !props.black && 'color: white;'}

    div {
        ${props => props.circled && `border: 4px solid ${props.black ? 'black;' : 'white;'}`}
        height: 50px;
        width: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
`

const ButtonNext = props => {
 return (
    <ButtonNextContainer black={props.black} centered={props.centered} circled={props.circled}>
        <div>&#8594;</div>
        <span>{props.text}</span>
    </ButtonNextContainer>
 )
}

export default ButtonNext;