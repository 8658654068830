import './Contact.scss'

import ButtonArrow from '../shared/ButtonArrow'

const Contact = props => {
    return (
        <div className="contact">
            <h2>You are extraordinary</h2>
            <div className="contactblock">
                <div className="contactblock-hero">
                    <span>Get in touch</span>
                </div>
                <div className="contactblock-form">
                    <form>
                        <span>My Name is </span>
                        <div className="inputcontainer">
                            <input placeholder="type here" />
                        </div>
                        <span>, get in touch with me at </span>
                        <div className="inputcontainer">
                            <input placeholder="your email" />
                        </div>
                        <span> or </span>
                        <div className="inputcontainer">
                            <input placeholder="your phone" />
                        </div>
                    </form>
                </div>
            </div>
            <ButtonArrow text="Send" />
        </div>
    )
}

export default Contact